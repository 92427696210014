<template>
  <div class="bg-gray-100 sm:mobile-viewport-fix lg:min-h-screen flex flex-col justify-between" v-if="$store.state.authIsReady">
    <div>
      <Popover v-if="$route.path !== '/dashboard/scan' && $route.path !== '/dashboard/substract'" as="template" v-slot="{ open }">
        <header :class="[open ? 'fixed inset-0 z-40 overflow-y-auto' : '', 'bg-white shadow-sm lg:static lg:overflow-y-visible']">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
              <div class="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                <div class="flex flex-shrink-0 items-center">
                  <router-link to="/hub" class="text-xs font-medium" v-if="!$store.state.isDriverOnline && $route.path !== 'dashboard/drivers'">
                    Jan-Art Sp. z o.o. <span class="opacity-20 ml-1 text-xxs">{{this.$store.state.app.version}}</span> 
                  </router-link>
                  <router-link to="/hub" class="text-xs font-medium" v-else>
                    Jan-Art Sp. z o.o.
                  </router-link>
                  <div class="text-xs font-medium text-red-500 select-none" v-if="!$store.state.isDriverOnline && $route.path === 'dashboard/drivers'">
                    Tryb offline
                  </div>
                </div>
              </div>
              <div class="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                <div class="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                  <div class="w-full">
                    <div class="relative">
                      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      </div>
                      <div class="py-2"></div>
                      <!-- <input id="search" name="search" class="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" placeholder="Search" type="search" /> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden" v-if="$store.state.isDriverOnline">
                <!-- Mobile menu button -->
                <PopoverButton class="-mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Open menu</span>
                  <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                  <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
              <div class="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                <!-- Profile dropdown -->
                <Menu as="div" class="relative ml-5 flex-shrink-0 select-none">
                  <div>
                    <MenuButton class="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span class="sr-only">Open user menu</span>
                      <img class="h-8 w-8 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${$store.state.userData.name} ${$store.state.userData.surname}`" alt="" />
                    </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem >
                          <div @click.prevent="toHub" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">Zmień moduł</div>
                      </MenuItem>
                      <MenuItem >
                          <div @click.prevent="logout" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">Wyloguj się</div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
          </div>
          <PopoverPanel as="nav" class="lg:hidden" aria-label="Global">
            <div class="border-t border-gray-200 pt-4 pb-3 select-none">
              <div class="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                <div class="flex-shrink-0">
                  <img class="h-10 w-10 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${$store.state.userData.name} ${$store.state.userData.surname}`" alt="" />
                </div>
                <div class="ml-3 select-none">
                  <div class="text-base font-medium text-gray-800">{{ $store.state.userData.name }}</div>
                  <div class="text-sm font-medium text-gray-500">{{ $store.state.userData.surname }}</div>
                </div>
              </div>
              <div class="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                <div @click.prevent="toHub" class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Zmień moduł</div>
                <div @click.prevent="logout" class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Wyloguj się</div>
              </div>
            </div>
          </PopoverPanel>
        </header>
      </Popover>
      <div class="py-6">
        <div class="mx-auto px-4 sm:px-6 lg:grid lg:max-w-7xl lg:gap-8 lg:px-8">
          <main class="w-full">
            <h1 class="text-2xl font-semibold text-gray-900 mt-2 mb-2 select-none" v-if="$route.path !== '/dashboard/scan' && $route.path !== '/dashboard/substract' && $route.path !== '/dashboard/storage/scan' && $route.meta.title !== undefined">{{$route.meta.title}}</h1>
            <router-view></router-view>
          </main>
        </div>
      </div>
    </div>
    <footer v-if="$store.state.authIsReady && $route.path !== '/dashboard/scan' && $route.path !== '/dashboard/substract' && $route.path !== '/dashboard/drivers/qr' " class="bg-white">
      <div class="mx-auto max-w-7xl py-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div class=" md:order-1 md:mt-0 w-full">
          <p class="text-center text-xs text-gray-400">&copy; {{moment().year()}} Powered by <a href="https://complaia.systems/" class="transition ease-in-out duration-300 text-transparent font-medium bg-clip-text bg-gradient-to-r from-purple-500 to-blue-500 opacity-90 hover:opacity-100">Complaia Systems</a></p>
        </div>
      </div>
    </footer>
  </div>
  <transition name="fade">
    <Notification v-if="$store.state.notification.show" />
  </transition>
  <transition name="fade">
    <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader" />
  </transition>
</template>

<script>
import {db, auth} from "@/firebase/gfbconf.js"
import moment from 'moment';
import Loader from '../components/Loader.vue';
import Notification from '@/components/Notification.vue';
import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { MenuIcon as Bars3Icon, XIcon as XMarkIcon } from '@heroicons/vue/solid'
//import axios from 'axios';
export default {
  name: "Template",
  data() {
    return {
    }
  },
  components: {
    Notification ,Loader, Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel, Bars3Icon, XMarkIcon
  },
  created()
  {
    this.moment = moment;
    moment.locale('pl')
    // Auth guard
    const loginSubscription = auth.onAuthStateChanged(async (user) => {
      if(user)
      {
        this.$store.commit("setUser", user);
        // Get user data by id from db
        let userCred = await db.collection("UsersData").doc(this.$store.state.user.uid).get();
        if(userCred.exists)
        {
          this.$store.commit('setUserCredentials', userCred.data());
          this.$store.commit("setAuthIsReady", true);
          this.$store.commit("resetGlobalLoader");
        }
        else
        {
          this.$router.push("/");
        }
      }
      else
      {
        this.$router.push("/");
      }
    })
  },
  watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  methods: {
    toHub()
    {
      this.$router.push('/hub');
    },
    async logout()
    {
      try {
        this.$store.commit('setGlobalLoader',{
          show: true,
          head: "Sprawdzanie twoich danych..",
          subheader: `Nie zamykaj okna.`,
          success: false
        });
        await this.$store.dispatch("logout");
        this.$router.push("/");
      } catch (err)
      {
        console.log(err.message);
      }
    }
  },
  computed: {
  }
}
</script>
<style scoped>
  .mobile-viewport-fix {
    min-height: calc(var(--vh, 1vh) * 100);
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: stretch;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>